const styles = theme => ({
  outer: {
    marginTop: 60,
    textAlign: 'center'
  },
  tak: {
    width: 120,
    marginBottom: 30
  },
  name: {
    fontSize: 23,
    letterSpacing: 2,
    marginBottom: 10
  },
  date: {
    letterSpacing: 2,
    marginBottom: 40
  },
  link: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  message: {
    marginBottom: 40
  },
  videoOuter: {
    paddingTop: '56.25%',
    position: 'relative',
    marginBottom: 40
  },
  video: {
    position: 'absolute',
    top:0,
    left:0,
    width: '100%',
    height: '100%'
  }
});

export default styles

import React from "react"
import styles from '../styles/index';
import { 
  withStyles,
  Container,
  Grid
} from '@material-ui/core';

function IndexPage (props) {
  const { classes } = props;

  return (
    <Container maxWidth="sm">
      <div className={classes.outer}>
        <img src="/tak.svg" className={classes.tak} />
        <div className={classes.name}>HETTY LABAN - DUIJST</div>
        <div className={classes.date}>24 DECEMBER 1965 - 30 MAART 2020</div>
        <a className={classes.link} href="/liturgie-hetty-laban.pdf" target="_blank">Download liturgie</a> &nbsp;&nbsp; · &nbsp;&nbsp; <a className={classes.link} href="https://youtu.be/u0n7woR-okA" target="_blank">Bekijk de dienst</a>
      </div>
    </Container>
  )
}

export default withStyles(styles)(IndexPage)
